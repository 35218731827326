a {
  color: #5a5a5a ;
}

#main-menu .menu-ul li {
  a {
    font-size: 80%;
  }
  &:nth-last-child(2), &:nth-last-child(1) {
    background-color: #5a5a5a;
    padding-bottom: 3px;
    padding-top: 0px;
  }
  &:nth-last-child(2) a, &:nth-last-child(1) a {
    color: white;
    font-size: 80%;
  }
}

// .scroll #main-menu {
//   .last-li a:hover {
//     color: #000;
//   }
// }

.main-site .wrapper.spotlight .content {
  padding: 0px 50px;
}

.button {
  box-shadow: inset 0 0 0 1px rgba(47, 47, 47, 0.46);
  // box-shadow: inset 0 0 0 1px rgba(47, 47, 47, 0.26);
  color: rgba(47, 47, 47, 0.66) !important;
  // color: rgb(34, 150, 243) !important;
  text-transform: lowercase;
  height: 2.75em;
  line-height: 2.75em;
  margin: 15px 0px;
}

p {
  color: #555;
  /* color: rgb(121, 121, 121); */
  font-size: 18px;
  line-height: 29px;
}

a.special:not(.button) {
  text-transform: lowercase;
  text-align: right;
  display: inline-block;
}

span.label {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 3px 15px;
  border-radius: 20px;
  padding-bottom: 6px;
  @media(max-width: 942px) {
    padding: 3px 10px;
  }
  @media(max-width: 910px) {
    padding: 3px 12px;
  }
  @media(max-width: 858px) {
    padding: 3px 21px;
  }
  @media(max-width: 820px) {
    font-size:15px;
  }
  @media(max-width: 336px) {
    font-size:13px;
  }
}

.start-form input {
  color: #666;
}

.zoom-img .image .magnifier {
  display: none;
}

.img2 {
  border-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border: 0px solid #f5f5f5;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 13px 0px;
  @media(max-width: 991px) {
    width: 300px;
    height: 300px;
  }
  @media(max-width: 800px) {
    width: 200px;
    height: 200px;
  }
}

.inner {
  background-color: transparent;
  @media(max-width: 641px) {
    text-align: center !important;
  }
}

.functions-wrapper {
  // margin: 3.4em 0;
  margin: 0;
  .wrapper {
    margin: 0px;
    // padding: 4em 0;
    padding: 3.1em 0;
    &.style3 {
      background-color: rgb(248, 248, 248);
      border-bottom: 1px solid #f1f1f1;
      border-top: 1px solid #f1f1f1;
    }
  }
}

input[type="submit"] {
  box-shadow: none;
}

.start-form {
  background-color: rgba(0, 0, 0, 0.3);
}

.logo {
  display: none;
  transition: color .2s ease-in-out;

}

.scroll .logo {
  display: block;
  opacity: 0;
}

h2 {
  &.major {
    padding-bottom: 0px;
    border-bottom: 0px;   
    color: rgb(32, 32, 32);
    @media(max-width: 991px) {
      text-align: center;
    }
  }
  font-size: 1.4em;
}

#main-menu .menu-ul li  a {
  color: white;
  // transition: color .2s ease-in-out;
}

#page-wrapper.scroll #main-menu .menu-ul li a {
  color: #5a5a5a !important;
}

#main-menu .menu-ul li {
  text-transform: none;
  padding-top: 30px;
  transition: padding-top .2s ease-in-out;
  &:nth-last-child(2), &:nth-last-child(1) {
    margin-top: 27px;
    transition: margin-top .2s ease-in-out;
    box-shadow: none;
    background-color: #2096f3;
  }
}

#page-wrapper {
  &.scroll #main-menu .menu-ul li {
    &:nth-last-child(2), &:nth-last-child(1) {
      margin-top: 17px;
      padding-top: 0px;
    }
    &:nth-last-child(2) a, &:nth-last-child(1) a {
      color: white !important;
    }
    &:nth-last-child(2) a:hover, &:nth-last-child(1) a:hover {
      color: #000 !important;
    }
    padding-top: 20px;
  }
  #header {
    padding-top: 15px;
    box-shadow: none;
    background: #636363;
    transition: all .2s ease-in-out;
  }
  &.scroll #header {
    height: 3.5em;
    padding-top: 5px;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.2) 0 1px 5px;
  }
}

#page-wrapper.main-page {
  #header {
    background: transparent;
  }
}

#page-wrapper.main-page.scroll {
  #header {
    background: #fff;
  }
}
.inner .content .function-button:hover {
  background-color: #2296f3 !important;
}
.main-site {
  .wrapper {
    border-bottom: 1px solid #f1f1f1;
    /*background-color: #f5f5f5 !important;*/
    &.second {
      background-color: #fff;
    }
    &.first {
      box-shadow: rgba(0, 0, 0, 0.1) 0px -10px 18px;
      background-color: rgb(248, 248, 248);
      border-bottom: 1px solid #f1f1f1;
    }
    &.third {
      background-color: rgb(248, 248, 248);
    }
  }
  .spotlight .image {
    box-shadow: none;
    border: 1px solid #f1f1f1;
  }
}
.prices article {
  box-shadow: none;
  margin:10px !important;
}
.prices article:first-child {
  margin-left:0px;
}
.prices  article button {
  box-shadow: inset 0 0 0 1px rgba(47, 47, 47, 0.36);
  text-transform: none;
}

.feature-teaser {
  float:left;
  border: 0px solid #ccc;
  max-width:50%;
  h4.major {
    font-size: 1.2em !important;
  }
  p {
    text-align:left;
  }
}
.feature-teaser:nth-child(2n - 1) {
  padding-left:20px;
}
.feature-teaser:nth-child(2n) {
  padding-right:20px;
}

.feature-teaser.function-box {
  padding-left: 15px !important;
  padding-right: 15px !important;
  width: 600px;
  @media(max-width: 408px){
    padding-left: 5px !important;
  padding-right: 5px !important;

  }
  // border: 1px solid red;
  height: 230px;
  p {
    text-align: justify;
  }
  @media(max-width: 714px){
    max-width: 100% !important;
    height: auto;
  }
}

#main-menu .menu-ul li {
  padding-top: 27px;
  padding-bottom: 23px;
}

@media screen and (max-width: 1680px) {
  #main-menu .menu-ul li {
    a {
      font-size: 90%;
    }
    text-transform: none;
    padding-top: 23px;
    padding-bottom: 23px;
    transition: padding-top .2s ease-in-out;
    &:nth-last-child(2), &:nth-last-child(1) {
      margin-top: 22px;
      transition: margin-top .2s ease-in-out;
    }
  }
  #page-wrapper {
    &.scroll #main-menu .menu-ul li {
      &:nth-last-child(2), &:nth-last-child(1) {
        margin-top: 16px;
        padding-top: 0px;
      }
      padding-top: 17px;
      margin-bottom: 1em;
    }
    #header {
      padding-top: 15px;
      transition: all .2s ease-in-out;
    }
    &.scroll #header {
      height: 3.5em;
      padding-top: 5px;
    }
  }
}

@media screen and (max-width: 1160px) {
  #main-menu .menu-ul li {
    a {
      font-size: 100%;
    }
    &:nth-last-child(2) a, &:nth-last-child(1) a {
      font-size: 100%;
      padding: 2px 5px;
    }
  }
}